import { useState, useMemo, FC, useEffect, useCallback } from 'react'

import { TrashIcon } from '@heroicons/react/24/outline'
import Divider from '@mui/material/Divider'
import TextField from '@mui/material/TextField'
import { SUPPORTED_FILE_MIME_TYPES } from 'api/src/common/enums'
import {
  updateClientMutation,
  updateClientMutationVariables,
  HeaderType,
} from 'types/graphql'

import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import useScrollToAnchor from 'src/lib/hooks/UseScrollToAnchor'
import { useAuth } from 'src/Providers'

import FormInputRow from '../Forms/FormInputRow/FormInputRow'
import Button from '../Library/Button/Button'
import DragAndDropFileUpload from '../Library/DragAndDropFileUpload/DragAndDropFileUpload'
import { Settings } from '../SettingsClientConfigurationCell'

const UPDATE_CLIENT_SETTINGS = gql`
  mutation updateClientMutation($id: Int!, $input: UpdateClientInput!) {
    updateClient(id: $id, input: $input) {
      name
      notificationEmail
      logoStorageObjectId
      logomarkStorageObjectId
      headerStorageObjectId
      knowledgeBaseStorageObjectId
      primaryColor
      secondaryColor
      headerType
    }
  }
`

interface Props {
  settings: Settings
}

interface StorageObjectProps {
  id: number
  downloadUrl: string
  fileType: HeaderType
}

const SettingsClientConfiguration: FC<Props> = ({ settings }) => {
  const { currentUser } = useAuth()
  const clientId = useMemo(
    () => currentUser?.parentData?.id ?? '',
    [currentUser],
  )

  // listens for url hash changes and scrolls to the anchor
  useScrollToAnchor(500)

  const [settingsLogo, setSettingsLogo] = useState(settings.logoStorageObject)
  const [settingsLogomark, setSettingsLogomark] = useState(
    settings.logomarkStorageObject,
  )
  const [settingsHeader, setSettingsHeader] = useState(
    settings.headerStorageObject,
  )
  const [settingsHeaderType, setSettingsHeaderType] = useState<HeaderType>(
    settings.headerType,
  )

  const [settingsKBHeader, setSettingsKBHeader] = useState(
    settings.knowledgeBaseStorageObject,
  )

  const [hasChanged, setHasChanged] = useState<boolean>(false)

  const handleLogoUpload = (storageObject: StorageObjectProps) => {
    setSettingsLogo(storageObject)
    setHasChanged(true)
  }

  const handleLogomarkUpload = (storageObject: StorageObjectProps) => {
    setSettingsLogomark(storageObject)
    setHasChanged(true)
  }

  const handleHeaderUpload = (storageObject: StorageObjectProps) => {
    setSettingsHeader(storageObject)
    setSettingsHeaderType(storageObject.fileType)
    setHasChanged(true)
  }

  const handleKnowledgeBaseHeaderUpload = (
    storageObject: StorageObjectProps,
  ) => {
    setSettingsKBHeader(storageObject)
    setHasChanged(true)
  }

  const [updateClient] = useMutation<
    updateClientMutation,
    updateClientMutationVariables
  >(UPDATE_CLIENT_SETTINGS, {
    onCompleted: () => {
      toast.success('Client Settings saved')
    },
    onError: () => {
      toast.error(
        'Could not save Client Settings.\nAn unexpected error occurred.',
      )
    },
  })

  const updateClientSettings = useCallback(async () => {
    await updateClient({
      variables: {
        id: settings.id,
        input: {
          logoStorageObjectId: settingsLogo?.id,
          logomarkStorageObjectId: settingsLogomark?.id,
          headerStorageObjectId: settingsHeader?.id,
          headerType: settingsHeaderType,
          knowledgeBaseStorageObjectId: settingsKBHeader?.id,
        },
      },
    })
  }, [
    settings.id,
    settingsLogo,
    settingsLogomark,
    settingsHeader,
    settingsHeaderType,
    updateClient,
    settingsKBHeader,
  ])

  // Autosave when logos or email are changed
  useEffect(() => {
    if (hasChanged) {
      updateClientSettings()
      setHasChanged(false)
    }
  }, [hasChanged, updateClientSettings])

  return (
    <div className="my-10 px-6">
      <div className="flex flex-wrap items-center justify-between gap-4">
        <div>
          <h3 className="text-lg font-medium leading-6 text-gray-900">
            Business Information
          </h3>
          <p className="max-w-2xl text-sm text-gray-500">
            Settings for business identity information, ownership and billing
            details.
          </p>
        </div>
      </div>

      <Divider className="my-6" />

      <FormInputRow title="Company Name">
        <TextField
          variant="outlined"
          size={'small'}
          fullWidth
          value={settings.name}
          disabled
          className="bg-gray-50"
        />
      </FormInputRow>

      <Divider className="my-6 mt-16 border-0" />

      <div className="flex flex-wrap items-center justify-between gap-4">
        <div>
          <h3 className="text-lg font-medium leading-6 text-gray-900">
            Branding
          </h3>
          <p className="max-w-2xl text-sm text-gray-500">
            Settings for controlling branding throughout the Hub.
          </p>
        </div>
      </div>

      <Divider className="my-6" />

      <FormInputRow title="Logo">
        {!settingsLogo?.downloadUrl && (
          <DragAndDropFileUpload
            name="logoFile"
            acceptedFileTypes={[SUPPORTED_FILE_MIME_TYPES.IMAGE]}
            description="PNG, JPG or GIF with transparent background (max 1920x1080px)"
            storageObjectType={{
              prefixes: [`${clientId}`, 'client', 'settings'],
              fileName: 'logo',
            }}
            onUpload={(storageObject) => {
              handleLogoUpload(storageObject)
            }}
          />
        )}
        {settingsLogo?.downloadUrl && (
          <div className="flex flex-col gap-y-4">
            <div className="flex w-full items-center justify-end">
              <Button
                startIcon={<TrashIcon className="h-4 w-4 text-red-500" />}
                fullWidth={false}
                variant="text"
                className="min-w-[0] px-3 text-red-400"
                onClick={() => {
                  setSettingsLogo({ id: null, downloadUrl: null })
                  setHasChanged(true)
                }}
              >
                Remove
              </Button>
            </div>
            <div className="flex gap-4">
              <div className="max-w-1/2 grow">
                <p className="mb-2 text-sm text-gray-500">
                  Light background preview
                </p>
                <img
                  alt="Light Logo"
                  className="h-32 w-full rounded-lg bg-gray-200 object-contain p-6"
                  src={settingsLogo?.downloadUrl}
                />
              </div>
              <div className="max-w-1/2 grow">
                <p className="mb-2 text-sm text-gray-500">
                  Dark background preview
                </p>
                <img
                  alt="Dark Logo"
                  className="h-32 w-full rounded-lg bg-gray-800 object-contain p-6"
                  src={settingsLogo?.downloadUrl}
                />
              </div>
            </div>
          </div>
        )}
      </FormInputRow>

      <Divider className="my-3 border-0" />

      <FormInputRow
        title="Logomark"
        note="This is a smaller version of your logo, typically used for your social media profiles."
      >
        {!settingsLogomark?.downloadUrl && (
          <DragAndDropFileUpload
            name="logomarkFile"
            acceptedFileTypes={[SUPPORTED_FILE_MIME_TYPES.IMAGE]}
            description="PNG, JPG or GIF (max 300x300px)"
            storageObjectType={{
              prefixes: [`${clientId}`, 'client', 'settings'],
              fileName: 'logomark',
            }}
            onUpload={(storageObject) => {
              handleLogomarkUpload(storageObject)
            }}
          />
        )}
        {settingsLogomark?.downloadUrl && (
          <div className="flex flex-col gap-y-4 pb-4">
            <div className="flex w-full items-center justify-end">
              <Button
                startIcon={<TrashIcon className="h-4 w-4 text-red-500" />}
                fullWidth={false}
                variant="text"
                className="min-w-[0] px-3 text-red-400"
                onClick={() => {
                  setSettingsLogomark({ id: null, downloadUrl: null })
                  setHasChanged(true)
                }}
              >
                Remove
              </Button>
            </div>
            <div className="flex space-x-4">
              <div>
                <p className="mb-2 max-w-2xl text-sm text-gray-500">
                  Light background preview
                </p>
                <img
                  alt="Light Logo"
                  className="h-32 rounded-lg bg-gray-200 p-4"
                  src={settingsLogomark?.downloadUrl}
                />
              </div>
              <div>
                <p className="mb-2 max-w-2xl text-sm text-gray-500">
                  Dark background preview
                </p>
                <img
                  alt="Dark Logo"
                  className="h-32 rounded-lg bg-gray-800 p-4"
                  src={settingsLogomark?.downloadUrl}
                />
              </div>
            </div>
          </div>
        )}
      </FormInputRow>

      <Divider className="my-3 border-0" />

      <FormInputRow
        title="Homepage Header"
        note="image and video types are accepted (max 10mb)."
      >
        {!settingsHeader?.downloadUrl && (
          <DragAndDropFileUpload
            name="headerFile"
            acceptedFileTypes={[
              SUPPORTED_FILE_MIME_TYPES.IMAGE,
              SUPPORTED_FILE_MIME_TYPES.VIDEO,
            ]}
            description="MP4, MOV, AVI, PNG, JPG or GIF (max 1920x1080px)"
            storageObjectType={{
              prefixes: [`${clientId}`, 'client', 'settings'],
              fileName: 'header',
            }}
            onUpload={(storageObject) => {
              handleHeaderUpload(storageObject)
            }}
          />
        )}
        {settingsHeader?.downloadUrl && (
          <div className="flex flex-col gap-y-4">
            <div className="flex w-full items-center justify-end">
              <Button
                startIcon={<TrashIcon className="h-4 w-4 text-red-500" />}
                fullWidth={false}
                variant="text"
                className="min-w-[0] px-3 text-red-400"
                onClick={() => {
                  setSettingsHeader({ id: null, downloadUrl: null })
                  setHasChanged(true)
                }}
              >
                Remove
              </Button>
            </div>
            <div className="relative">
              {settingsLogo?.downloadUrl && (
                <img
                  alt="Logo"
                  className="absolute left-1/2 top-1/2 h-32 translate-x-[-50%] translate-y-[-50%]"
                  src={settingsLogo?.downloadUrl}
                />
              )}
              {settingsHeaderType === 'IMAGE' && (
                <div>
                  <img
                    src={settingsHeader?.downloadUrl}
                    alt=""
                    className="w-full rounded-lg object-fill"
                  />
                </div>
              )}
              {settingsHeaderType === 'VIDEO' && (
                <video
                  autoPlay
                  loop
                  muted
                  playsInline
                  className="h-full w-full rounded-lg object-cover"
                >
                  <source src={settingsHeader?.downloadUrl} type="video/mp4" />
                  Your browser does not support the video tag,
                  Chrome/Safari/Firefox are supported.
                </video>
              )}
            </div>
          </div>
        )}
      </FormInputRow>
      <Divider className="my-3 border-0" />

      <FormInputRow title="Knowledge Base Header" id="knowledgeBaseImage">
        {!settingsKBHeader?.downloadUrl && (
          <DragAndDropFileUpload
            name="headerFileKnowledgeBase"
            acceptedFileTypes={[SUPPORTED_FILE_MIME_TYPES.IMAGE]}
            description="PNG, JPG or GIF (max 1920x1080px)"
            storageObjectType={{
              prefixes: [`${clientId}`, 'client', 'settings'],
              fileName: 'headerknowledgebase',
            }}
            onUpload={(storageObject) => {
              handleKnowledgeBaseHeaderUpload(storageObject)
            }}
          />
        )}
        {settingsKBHeader?.downloadUrl && (
          <div className="flex flex-col gap-y-4">
            <div className="flex w-full items-center justify-end">
              <Button
                startIcon={<TrashIcon className="h-4 w-4 text-red-500" />}
                fullWidth={false}
                variant="text"
                className="min-w-[0] px-3 text-red-400"
                onClick={() => {
                  setSettingsKBHeader({ id: null, downloadUrl: null })
                  setHasChanged(true)
                }}
              >
                Remove
              </Button>
            </div>
            <div className="relative">
              <div>
                <img
                  src={settingsKBHeader?.downloadUrl}
                  alt=""
                  className="w-full rounded-lg object-fill"
                />
              </div>
            </div>
          </div>
        )}
      </FormInputRow>
    </div>
  )
}

export default SettingsClientConfiguration
